<!--订单列表-->
<template>
  <div class="account-model">


    <div class="global-view-table">
      <div class="card_main">
        <el-card style="width: 33%;">
          <div class="card_main_title">逾期手动扣款</div>
          <div class="card_main_content">
            <el-input type="number" v-model="overdueDays" clearable/>
            <div class="card_main_content_span">注意：逾期几天 就写负几天</div>
          </div>
          <div class="card_main_footer">
            <el-button :disabled="!overdueDays" type="primary" :loading="btnLoading" @click="repayOverdue()">确定</el-button>
          </div>
        </el-card>

        <el-card style="width: 33%;">
          <div class="card_main_title">还款日当天还款</div>
          <div class="card_main_content">

          </div>
          <div class="card_main_footer">
            <el-button type="primary" :loading="btnLoading" @click="currentDayRepay()">确定</el-button>
          </div>
        </el-card>
      </div>


    </div>
  </div>
</template>

<script>
import {
  currentDayRepay, repayOverdue
} from "@/api/business";


export default {
  name: "repay-action",
  data() {
    return {
      overdueDays:null,
      btnLoading:false
    };
  },
  created() {},
  methods: {
    repayOverdue() {
      this.$messageBox
          .confirm("确认手动扣款？", "确认逾期手动还款", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            this.btnLoading = true;
            repayOverdue({
              overdueDays: this.overdueDays,
            }).then(() => {
              this.$message.success("提交成功");
              this.overdueDays = null;
              this.btnLoading = false;
            })
          })
          .catch(() => {
            this.$message.info('已取消');
          });
    },

    currentDayRepay() {
      this.$messageBox
          .confirm("是否确认还款日当天还款？", "确认还款日当天还款", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            this.btnLoading = true;
            currentDayRepay({}).then(() => {
              this.$message.success("提交成功");
              this.btnLoading = false;
            }).catch(()=>{
              this.btnLoading = false;
            });
          })
          .catch(() => {
            this.$message.info('已取消')
          });
    }
  }
};
</script>

<style lang="scss">
.account-model {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;

  .global-view-table {
    height: auto;
    margin-top: 16px;
    padding: 16px;

    .card_main {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      &_title {
        font-size: 15px;
        font-weight: 600;
      }

      &_content {
        margin-top: 10px;
        min-height: 60px;

        &_span{
          margin-top: 5px;
        }


      }

      &_footer{
        margin-top: 20px;
      }
    }
  }

  .role-tag {
    margin-right: 8px;
  }
}

.account-dialog {
  .el-dialog__body {
    padding: 24px 24px 0;
  }
}
</style>
